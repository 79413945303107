<template>
  <svg :style="{width: size, height: size}" width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.942871" y="7.90833" width="2.8" height="2.8" rx="1.4" :fill="colorGenerator(1)"/>
    <rect x="4.67627" y="5.10834" width="2.8" height="5.6" rx="1.4" :fill="colorGenerator(2)"/>
    <rect x="8.40942" y="2.30829" width="2.8" height="8.4" rx="1.4" :fill="colorGenerator(3)"/>
    <rect x="12.1428" y="0.44165" width="2.8" height="10.2667" rx="1.4" :fill="colorGenerator(4)"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconPriority',
    props: {
      primaryColor: {
        type: String,
        default: '#6A47E9'
      },
      secondaryColor: {
        type: String,
        default: '#81848B'
      },
      priority: {
        type: Number,
        default: 3
      },
      size: {
        type: Number,
        default: 15
      }
    },
    methods: {
      colorGenerator(position) {
        if(position <= this.priority) {
          return this.primaryColor
        }
        return this.secondaryColor
      }
    }
  }
</script>
